// src/App.tsx
import React from 'react'
import BackgroundVideo from './BackgroundVideo'

const App: React.FC = () => {
  return (
    <div>
      <BackgroundVideo />
      {/* Autres éléments de la page */}
    </div>
  )
}

export default App
