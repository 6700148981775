// src/BackgroundVideo.tsx
import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Confetti from 'react-confetti'

const BackgroundVideo: React.FC = () => {
  const [isMuted, setIsMuted] = useState(true)
  const [isStarted, setIsStarted] = useState(false)
  const [isCountdownVisible, setIsCountdownVisible] = useState(true)
  const [countdown, setCountdown] = useState<number | null>(null)
  const playerRef = useRef<ReactPlayer>(null)

  // Fonction pour activer ou désactiver le son
  const toggleMute = () => {
    setIsMuted(!isMuted)
  }

  const startVideoWithCountdown = () => {
    playerRef.current?.seekTo(5.2, 'seconds')
    toggleMute() // Désactive le son
    setIsStarted(true) // Lance la vidéo
    setCountdown(3) // Lance le décompte à partir de 3
  }

  // Décompte avant de masquer le voile et d'afficher le texte d'anniversaire
  useEffect(() => {
    if (countdown !== null && countdown > 0) {
      const timer = setTimeout(
        () => setCountdown(countdown - 1),
        countdown === 1 ? 1500 : 2600
      )
      return () => clearTimeout(timer)
    } else if (countdown === 0) {
      setIsCountdownVisible(false) // Masque le décompte
    }
  }, [countdown])

  // Fonction pour gérer la boucle avec les points de début et de fin
  const handleProgress = (state: { playedSeconds: number }) => {
    if (state.playedSeconds >= 19.4 && playerRef.current) {
      playerRef.current.seekTo(11, 'seconds')
    }
  }

  return (
    <div style={styles.container}>
      <ReactPlayer
        ref={playerRef}
        url="https://www.youtube.com/watch?v=bhTFZ7R0WHM" // Remplace par l'URL YouTube
        muted={isMuted}
        loop
        playing
        width="100%"
        height="100%"
        onProgress={handleProgress}
        style={{
          ...styles.video,
          display: !isCountdownVisible ? 'block' : 'none',
        }}
      />
      {/* Effets de confettis */}
      {!isCountdownVisible && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
      {/* Voile en dégradé bleu vers violet */}
      <div style={styles.overlay}></div>

      {/* Décompte ou texte d'anniversaire */}
      {isCountdownVisible ? (
        <h1 style={styles.blinkingText}>{countdown}</h1>
      ) : (
        <h1 style={styles.blinkingText}>Bon anniversaire Joseph !</h1>
      )}

      {/* Bouton de démarrage de la vidéo et du décompte */}
      {!isStarted && (
        <button onClick={startVideoWithCountdown} style={styles.startButton}>
          Lancer la surprise !
        </button>
      )}

      {/* Bouton pour activer/désactiver le son après le lancement */}
      {isStarted && (
        <button onClick={toggleMute} style={styles.button}>
          {isMuted ? 'Activer le son' : 'Désactiver le son'}
        </button>
      )}
    </div>
  )
}

const styles = {
  container: {
    position: 'relative' as const,
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  overlay: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to bottom right, rgba(0, 0, 255, 0.5), rgba(128, 0, 128, 0.5))',
    zIndex: -1,
  },
  blinkingText: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Comic Sans MS, sans-serif',
    fontSize: '3rem',
    textAlign: 'center' as const,
    animation: 'blinking 1.5s infinite',
    fontWeight: 'bold' as const,
  },
  startButton: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px 20px',
    fontSize: '24px',
    fontFamily: 'Comic Sans MS, sans-serif',
    backgroundColor: '#ff69b4',
    color: '#fff',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  button: {
    position: 'absolute' as const,
    top: '20px',
    right: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
}

// Ajouter une animation de clignotement avec changement de couleur
const styleSheet = document.styleSheets[0]
styleSheet.insertRule(
  `
  @keyframes blinking {
    0% { color: #ff0000; opacity: 1; }
    25% { color: #00ff00; opacity: 0.5; }
    50% { color: #0000ff; opacity: 1; }
    75% { color: #ffff00; opacity: 0.5; }
    100% { color: #ff00ff; opacity: 1; }
  }
`,
  styleSheet.cssRules.length
)

export default BackgroundVideo
